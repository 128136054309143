import React from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { Link } from 'gatsby';
import { ClientOnboardingForm } from '../components/ClientOnboardingForm/index';
import ContactForm from '../components/ContactForm'; //wtf!? check repo 'features' for imports

interface Props {}

const ThankYou: React.FC = () => {
  return (
    <DefaultLayout>
      <div className='container max-w-lg mx-auto mt-10'>
        {/*<ClientOnboardingForm />*/}
        <ContactForm />
        <Link className='text-primary-base my-24 text-center mx-auto block' to='/'>
          Go back to the Home Page
        </Link>
      </div>
    </DefaultLayout>
  );
};

export default ThankYou;
